<template>
  <b10-base>
    <b10-autocomplete
      v-if="showTinclusion"
      v-model="form.tinclusion"
      :items="tinclusion"
      item-value="id"
      item-text="descripcion"
      label="Tipo de inclusión"
      clearable
      :rules="formRules.tinclusion"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { TINCLUSION } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idttarifa: {
      type: Number,
      default: 0,
    },
    idbancoPrecio: {
      type: Number,
      default: 0,
    },
    idcliente: {
      type: Number,
      default: 0,
    },
    idarticulo: {
      type: [Number, String],
      default: '',
    },
    hasPermVerTarifas: {
      type: Boolean,
    },
  },
  data () {
    return {
      form: {
        tinclusion: null,
      },
      showTinclusion: false,
      formRules: {
        tinclusion: [
          v => !!v || 'Campo requerido'
        ]
      },
      tinclusion: [
        { id: TINCLUSION.instalacion, descripcion: 'Instalación' },
        { id: TINCLUSION.suministro, descripcion: 'Suministro' },
      ],
    }
  },
  async created () {
    await this.mostrarOcultarTipoInclusion()
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    async mostrarOcultarTipoInclusion () {
      const articulo = await this.$offline.articulo.row(this.idarticulo)
      this.tinclusion = await this.$offline.articulo.tinclusionArticulo(
        articulo.articulo.codigo,
        this.idttarifa,
        this.idbancoPrecio,
        this.hasPermVerTarifas
      )
      const preguntar = await this.$offline.articulo.preguntarTinclusion(
        articulo.articulo.codigo,
        this.idttarifa,
        this.idbancoPrecio
      )
      this.showTinclusion = preguntar.preguntar
      if (preguntar.tinclusion) {
        this.$set(this.form, 'tinclusion', preguntar.tinclusion)
      }
    },
  }
}
</script>
